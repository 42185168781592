<template>
  <div class="answer-tile">
    <DragDots class="answer-tile__dragdots" />
    <div class="question-title-input">
      <AppInput
        :value="item.text"
        :answerItem="item"
        ref="answer"
        name="answer"
        :label="answerLabel(index)"
        :showCheckBox="true"
        @blur="setField"
      />
    </div>
    <SmallCloseButton @click="remove" />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import SmallCloseButton from '@/components/Buttons/SmallCloseButton.vue';
import DragDots from '@/components/Icons/DragDots.vue';
import AppInput from '@/components/AppInput.vue';

export default {
  name: 'AnswerTile',
  props: {
    item: {
      type: Object,
      required: true,
    },
    question: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  components: {
    SmallCloseButton,
    DragDots,
    AppInput,
  },
  methods: {
    ...mapActions('quizTeacher', ['updateAnswer', 'removeAnswer']),
    setField(e) {
      if (this.item.text !== e.target.value) {
        this.updateAnswer({
          question: this.question,
          answer: { ...this.item, text: e.target.value },
        });
      }
    },
    remove() {
      this.removeAnswer({
        question: this.question,
        answer: this.item,
      });
    },
    answerLabel(index) {
      const numberSymbol = this.$i18n.locale === 'en' ? '#' : '№';
      return `${this.$t('placeholders.answer')} ${numberSymbol}${index + 1}`;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .form-group {
  margin-bottom: 0;
}

::v-deep input.form-control:focus {
  color: #f2f2f2;
  box-shadow: none;
}
</style>
