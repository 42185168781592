<template>
  <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14 9H8V15H6V9H0V7H6V1H8V7H14V9Z"
      fill="black"
    />
  </svg>
</template>

<script>
export default {
  name: 'PlusIconBlack',
};
</script>
