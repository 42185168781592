<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    @click="$emit('click')"
  >
    <path
      d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11
            12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
      fill="#F2F2F2"
      stroke="#F2F2F2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5
            11C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13Z"
      fill="#F2F2F2"
      stroke="#F2F2F2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4
            12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6Z"
      fill="#F2F2F2"
      stroke="#F2F2F2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5 6C5.55228 6 6 5.55228 6 5C6 4.44772 5.55228 4 5
            4C4.44772 4 4 4.44772 4 5C4 5.55228 4.44772 6 5 6Z"
      fill="#F2F2F2"
      stroke="#F2F2F2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523
            18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20Z"
      fill="#F2F2F2"
      stroke="#F2F2F2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5 20C5.55228 20 6 19.5523 6 19C6 18.4477 5.55228 18
            5 18C4.44772 18 4 18.4477 4 19C4 19.5523 4.44772 20 5 20Z"
      fill="#F2F2F2"
      stroke="#F2F2F2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'DragDots',
};
</script>
