<template>
  <b-list-group>
    <draggable
      :handle="handle"
      v-bind="dragOptions"
      v-model="draggableList"
      @start="drag = true"
      @end="drag = false"
    >
      <transition-group type="transition" :name="transitionName">
        <b-list-group-item
          class="list-draggable-item"
          v-for="(item, index) in list"
          :key="item._id || item.id || item.activity._id"
        >
          <component
            :is="computedComponent"
            :item="item.activity || item"
            :question="question"
            :routerPush="routerPush"
            :showDropDown="showDropDown"
            :index="index"
            :isManagerRole="isManagerRole"
            @remove="remove"
            @remove-from-program="removeFromProgram"
          />
        </b-list-group-item>
      </transition-group>
    </draggable>
  </b-list-group>
</template>

<script>
import draggable from 'vuedraggable';
import CourseCard from '@/components/Cards/CourseCard.vue';
import MaterialTile from '@/components/Cards/MaterialTile.vue';
import MaterialUserTile from '@/components/Cards/MaterialUserTile.vue';
import AnswerTile from '@/components/Cards/AnswerTile.vue';
import QuestionCardTeacher from '@/components/Cards/QuestionCardTeacher.vue';

export default {
  name: 'ContentList',
  components: {
    draggable,
    CourseCard,
    MaterialTile,
    MaterialUserTile,
    AnswerTile,
  },
  props: {
    isManagerRole: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'course',
    },
    list: {
      type: Array,
      required: true,
    },
    question: {
      type: Object,
      default: () => ({}),
    },
    disableDragAndDrop: {
      type: Boolean,
      default: false,
    },
    routerPush: {
      type: Function,
      default: () => {},
    },
    showDropDown: {
      type: Boolean,
      default: true,
    },
    handle: {
      type: String,
      default: '',
    },
    groupName: {
      type: String,
      default: 'draggable',
    },
  },
  data: () => ({
    drag: false,
  }),
  computed: {
    draggableList: {
      get() {
        return this.list;
      },
      set(val) {
        this.$emit('changeOrder', val);
      },
    },
    dragOptions() {
      return {
        animation: 200,
        group: this.groupName,
        disabled: this.disableDragAndDrop,
        ghostClass: 'ghost',
      };
    },
    computedComponent() {
      if (this.type === 'course') {
        return CourseCard;
      }
      if (this.type === 'material-user') {
        return MaterialUserTile;
      }
      if (this.type === 'material') {
        return MaterialTile;
      }
      if (this.type === 'answer') {
        return AnswerTile;
      }
      if (this.type === 'question') {
        return QuestionCardTeacher;
      }
      return CourseCard;
    },
    transitionName() {
      return this.$route.name !== 'CourseUser' ? 'list-fade' : '';
    },
  },
  methods: {
    remove(id) {
      this.$emit('remove', id);
    },
    removeFromProgram(id) {
      this.$emit('remove-from-program', id);
    },
  },
};
</script>

<style>
.list-draggable-item {
  transition: opacity 500ms ease-out;
  opacity: 1;
}

.list-fade-leave-to {
  transition: opacity 250ms ease-in;
  opacity: 0;
}

.list-fade-enter {
  opacity: 0;
}
</style>
