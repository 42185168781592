<template>
  <button class="big-button" @click="$emit('remove')">
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 4.55518H2.77778H17"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
            <!-- eslint-disable max-len -->
      <path
        d="M5.44609 4.55556V2.77778C5.44609 2.30628 5.63339 1.8541 5.96679 1.5207C6.30018 1.1873 6.75237 1 7.22386 1H10.7794C11.2509 1 11.7031 1.1873 12.0365 1.5207C12.3699 1.8541 12.5572 2.30628 12.5572 2.77778V4.55556M15.2239 4.55556V17C15.2239 17.4715 15.0366 17.9237 14.7032 18.2571C14.3698 18.5905 13.9176 18.7778 13.4461 18.7778H4.5572C4.0857 18.7778 3.63352 18.5905 3.30012 18.2571C2.96672 17.9237 2.77942 17.4715 2.77942 17V4.55556H15.2239Z"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.22058 9V14.3333"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.7794 9V14.3333"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </button>
</template>

<script>
export default {
  name: 'BigDeleteButton',
};
</script>
