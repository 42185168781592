<template>
  <div class="course-block">
    <div class="course-block__wrapper" @click="routerPush(item)">
      <div class="course-block__card-info card-info">
        <div class="card-info__image">
          <img :src="courseImage" alt="Изображение курса" />
        </div>
        <div class="card-info__info">
          <div class="card-info__title">{{ item.name || $t('placeholders.newCourse') }}</div>
          <div class="card-info__description">
            {{ item.description || $t('placeholders.noDescription') }}
          </div>
        </div>
      </div>
      <div class="course-block__action">
        <div class="action__top">
          <b-dropdown
            v-if="showDropDown"
            size="sm"
            dropleft
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
          >
            <template #button-content>
              <BigDeleteButton />
            </template>

            <b-dropdown-item href="#" @click.stop="$emit('remove-from-program', item._id)"
              >{{ $t('buttonLabels.fromTheProgram') }}
            </b-dropdown-item>
            <b-dropdown-item v-if="!isManagerRole" href="#" @click.stop="$emit('remove', item._id)">
              {{ $t('buttonLabels.permanently') }}
            </b-dropdown-item>
          </b-dropdown>
          <div v-else @click.stop="$emit('remove', item._id)">
            <BigDeleteButton />
          </div>
        </div>
        <div class="action__bottom">
          <img src="@/assets/images/svg/edit.svg" alt="Редактировать" />
          <span>{{ $t('buttonLabels.editCourse') }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BigDeleteButton from '@/components/Buttons/BigDeleteButton.vue';
import deviceMixin from '@/mixins/device.mixin';

export default {
  name: 'CourseCard',
  props: {
    isManagerRole: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      required: true,
    },
    routerPush: {
      type: Function,
      default: () => {},
    },
    showDropDown: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    BigDeleteButton,
  },
  mixins: [deviceMixin],
  computed: {
    courseImage() {
      let image = require('@/assets/images/png/course-thumbnail.png');
      if (this.item.meta?.imageHighres) {
        image = `https://${
          // eslint-disable-next-line no-nested-ternary
          this.$device.isDesktop
            ? this.item.meta.imageHighres
            : this.$device.isTablet
              ? this.item.meta.imageMidres
              : this.item.meta.imageLowres
        }`;
      }
      return image;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep button.dropdown-toggle:focus {
  box-shadow: none;
}
</style>
